:root {
  --black-color: #1c2434;
  --primary-color: #4e0c90;
  --alt-color: #f58b1d;
  --white-color: #fff;
  --radius: 10px;
  --text-clipPath: polygon(0 0, 100% 0, 100% 0, 0 0);
  --img-clipPath: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  --img-to-clipPath: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

body.home-body {
  height: 100vh;
  font-family: "Poppins";
  color: var(--white-color);
  cursor: crosshair;
  overflow-x: hidden;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

/* =================== Global =================== */
.dg.main .close-button.close-bottom {
  display: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none !important;
}

.mrv-button-container {
  margin-top: 24px;
}

.mrv-button,
.second-mrv-button,
.third-mrv-button {
  display: inline-block;
  background-color: transparent;
  border: 1px solid var(--white-color);
  color: var(--white-color);
  padding: 12px 30px;
  transition: border 0.4s ease-in-out, color 0.4s ease-in-out;
}

.mrv-button:hover,
.second-mrv-button:hover {
  color: var(--alt-color);
  border: 1px solid var(--alt-color);
}

.third-mrv-button:hover {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.marvel-site {
  position: relative;
  z-index: 1;
  width: 100%;
  display: grid;
}

.head-content {
  display: flex;
  gap: 5em;
  width: 100%;
  position: absolute;
  bottom: -50px;
  left: 30px;
}

.app--home-title {
  font-size: 8rem;
  width: 50vw;
  line-height: 97%;
  text-transform: capitalize;
}

.app--home-title,
.app--home-text {
  flex-basis: 0;
  flex-grow: 1;
}

.app--home-text {
  font-size: 1.3rem;
  width: 40vw;
  position: relative;
  top: 167px;
}

.head-poster {
  height: 600px;
  position: relative;
}

.head-poster-image {
  height: 600px;
  background-size: cover;
  clip-path: var(--img-clipPath);
}

.alt-card {
  padding: 20px;
}

.alt-card-img {
  height: 200px;
  border-radius: var(--radius);
  background: linear-gradient(45deg, var(--black-color), transparent);
  backdrop-filter: blur(10px);
  clip-path: var(--text-clipPath);
}

.alt-card-img-content {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.alt-card-body {
  padding-top: 24px;
}

.alt-card-title {
  font-size: 24px;
  margin-bottom: 16px;
  clip-path: var(--text-clipPath);
}

.alt-card-text {
  clip-path: var(--text-clipPath);
}

.cards:nth-of-type(2) {
  margin-top: 100px;
}

.cards:nth-of-type(3) {
  margin-top: 30px;
}

.cards:nth-of-type(4) {
  margin-top: 140px;
}

/* =================== Navbar =================== */
.nav {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  border: 1px solid #2a2f36;
}

.nav__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__logo {
  background-repeat: no-repeat;
  background-position: center;
  width: 148px;
  height: 46px;
  display: inline-block;
}

.nav__content {
  width: 100%;
  padding: 0 10rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 1300px) {
  .nav__content {
    padding: 0 4rem;
  }
}

@media (max-width: 1199px) {
  .nav__menu {
    display: none;
  }
}

.nav__menu-icon {
  padding: 0 10px;
  display: none;
  cursor: pointer;
  font-size: 35px;
}

@media (max-width: 1199px) {
  .nav__menu-icon {
    display: block;
  }
}

.nav__logo {
  padding: 43px 0;
}

.nav__link {
  display: inline-block;
  padding: 43px 10px;
  position: relative;
}

.nav__link:hover .nav__item {
  color: var(--alt-color);
}

.nav__link:hover .nav__sub {
  top: 80%;
  opacity: 1;
  visibility: visible;
}

.nav__item {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 700;
  transition: color 0.4s ease-in-out;
}

.nav__item:hover {
  color: var(--alt-color);
}

@media (max-width: 1199px) {
  .nav__logo img {
    width: 120px;
  }
}

.nav__sub {
  position: absolute;
  top: 100%;
  z-index: 1;
  width: 300px;
  background-color: #22272a;
  opacity: 0;
  visibility: hidden;
  transition: top 0.4s ease-in-out, opacity 0.4s ease-in-out,
    visibility 0.4s ease-in-out;
}

.nav__sub-link {
  padding: 12px 18px;
  font-size: 16px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.nav__sub-link:hover .nav__sub-item {
  color: var(--white-color);
}

.nav__sub-link:not(:first-child) {
  border-top: 1px solid #363b44;
}

.nav__sub-item {
  color: var(--white-color);
}

.nav__sub-item:hover {
  color: var(--white-color);
}

.nav__lang {
  background-color: #1e283d;
  padding: 4px;
  border-radius: 3px;
}

.nav__lang-flag {
  width: 16px;
  height: 11px;
  display: inline-block;
  background-repeat: no-repeat;
}

.nav__lang-text {
  background-color: transparent;
  border: none;
  color: var(--white-color);
}

.nav__profile-button {
  display: inline-block;
  padding: 11px 20px;
  background-color: var(--alt-color);
  color: var(--black-color);
  border-radius: 3px;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
}

.nav__profile-button:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
}

/* =================== Intro =================== */
.mrv__intro {
  height: 100vh;
  background: var(--primary-color) url("../static/intro-banner.png");
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mrv__info-title,
.mrv__info-para {
  clip-path: var(--text-clipPath);
  transform: translateY(-30px);
}

.mrv__info-title {
  width: 537px;
  font-size: 65px;
  margin-bottom: 20px;
  font-weight: 600;
}

.mrv__info-mark {
  color: var(--alt-color);
}

.mrv__info-para {
  color: #86909d;
  line-height: 25px;
}

.mrv__button,
.mrv__intro-img,
.mrv-button {
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
}

.mrv__button {
  display: inline-block;
  margin-top: 32px;
  padding: 17px 30px;
  background-color: var(--alt-color);
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--black-color);
  border-radius: 3px;
  text-decoration: none;
  transition: background-color 0.4s ease-in-out;
}

.mrv__button:hover {
  color: var(--white-color);
  text-decoration: none;
  background-color: var(--primary-color);
}

.mrv__intro-img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  animation: move 5s infinite;
}

@-webkit-keyframes move {
  0% {
    top: 0;
  }
  50% {
    top: 30px;
  }
  100% {
    top: 0;
  }
}

@keyframes move {
  0% {
    top: 0;
  }
  50% {
    top: 30px;
  }
  100% {
    top: 0;
  }
}

.abs-shape-1,
.abs-shape-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.abs-shape-1 {
  top: 0;
  left: 0;
  background-image: url("../static/ellipse-1.png");
}

.abs-shape-2 {
  top: 21rem;
  left: 20rem;
  background-image: url("../static/polygon-1.png");
}

@media (max-width: 991px) {
  .mrv__intro-img {
    display: none;
  }
}

@media (max-width: 1024px) {
  .mrv__info-title,
  .mrv__info-para,
  .mrv__button,
  .mrv__intro-img {
    clip-path: none;
  }
}

@media (max-width: 600px) {
  .mrv__info {
    text-align: center;
  }
  .mrv__info-title {
    width: 100%;
  }
  .mrv__button {
    margin-top: 0;
  }
}

@media (max-width: 414px) {
  .nav__content {
    padding: 0px 11px;
  }
  .mrv__intro {
    display: block;
  }
  .negative-space {
    width: 100%;
    height: 86px;
    margin-bottom: 6rem;
  }
  .mrv__info-title {
    font-size: 54px;
  }
}

/* =================== Mobile Menu =================== */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  right: -250px;
  z-index: 9999;
  width: 250px;
  height: 100%;
  background-color: var(--black-color);
  transition: left 0.4s ease, right 0.4s ease;
  overflow-y: scroll;
}

.sidebar.show {
  right: 0;
  left: 0px;
}

.sidebar-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px 10px 40px;
}

.sidebar-head .text {
  color: var(--white-color);
  font-weight: 600;
  line-height: 65px;
  background: var(--black-color);
  letter-spacing: 1px;
  font-size: 18px;
}

.aside-close {
  width: 27px;
  height: 28px;
  color: var(--white-color);
  background-color: #373737;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  font-size: 22px;
  transition: background-color 0.4s ease-in-out;
}

.sidebar ul {
  background: var(--black-color);
  width: 100%;
  list-style: none;
}

.sidebar ul li {
  line-height: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar ul li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.sidebar ul li a {
  position: relative;
  width: 100%;
  display: block;
  padding-inline: 40px;
  color: var(--white-color);
  text-decoration: none;
  font-weight: 500;
  border-right: 3px solid transparent;
  transition: all 0.4s;
  font-size: 18px;
}

.sidebar ul li a:hover {
  background-color: #1e1e1e;
}

.dropDown-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar ul li span {
  margin-inline: 20px;
  display: block;
  transition: transform 0.4s ease-in-out;
  background-color: #373737;
  font-size: 22px;
  cursor: pointer;
}

.sidebar ul li ul {
  position: static;
  background-color: #1e1e1e;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.sidebar ul li ul li {
  line-height: 42px;
  border-top: none;
}

.sidebar ul li ul li a {
  color: #e6e6e6;
  font-size: 16px;
}

.sidebar ul li ul li a:hover {
  color: var(--alt-color) !important;
}

.aside-close:hover {
  background-color: #ee0101;
}

.sidebar-lang {
  display: block;
  padding: 40px;
}

.none {
  display: none;
}

.sidebar .nav__lang {
  display: block;
  padding: 10px 40px;
}

/* =================== Services =================== */
.mrv-services {
  margin-top: 100px;
}
.mrv-services__title,
.mrv-services__text {
  clip-path: var(--text-clipPath);
}

/* =================== Features =================== */
.mrv-features {
  margin-top: 145px;
}
.mrv-feat__title,
.mrv-feat__text {
  clip-path: var(--text-clipPath);
}

/* =================== Works =================== */
.mrv-works {
  margin-top: 150px;
}

.mrv-works__title,
.mrv-works__text {
  clip-path: var(--text-clipPath);
}

.work-box {
  display: block;
  padding: 50px 30px;
  margin: 20px;
  height: 380px;
  background-size: contain;
  background-repeat: no-repeat;
  /* display: flex;
  align-items: end; */
  text-decoration: none !important;
  border-radius: var(--radius);
  overflow: hidden;
  clip-path: var(--text-clipPath);
}

.work-title {
  font-size: 2.5rem;
  color: var(--white-color);
  position: relative;
  top: -75px;
  clip-path: var(--text-clipPath);
}

/* =================== Blog =================== */
.blog-head {
  margin-bottom: 45px;
}

.blog-img {
  height: 314px;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 565px) {
  .blog-img {
    height: 277px;
  }
}

.blog-card-1 .blog-img,
.blog-card-2 .blog-img,
.blog-card-3 .blog-img {
  clip-path: var(--img-to-clipPath);
}

.blog-text {
  color: var(--white-color);
  clip-path: var(--text-clipPath);
}

.blog-title {
  font-size: 2.5rem;
  font-weight: bold;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.blog-text {
  font-size: 20px;
  clip-path: var(--text-clipPath);
}

.blog-card {
  width: 100%;
  display: inline-block;
  padding: 20px;
  text-decoration: none !important;
}

.blog-card-1,
.blog-card-2,
.blog-card-3 {
  clip-path: var(--img-clipPath);
}

.blog-card-title-1,
.blog-card-text-1,
.blog-card-title-2,
.blog-card-text-2,
.blog-card-title-3 {
  clip-path: var(--text-clipPath);
}

.blog-body {
  padding: 20px 0;
}

.blog-card-title {
  width: 500px;
  margin: 0;
  margin-bottom: 12px;
  color: var(--white-color);
}

.blog-card-text {
  width: 500px;
  overflow: hidden;
  color: var(--white-color);
}

.section-blog,
.section-prod {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.section-blog {
  background: linear-gradient(45deg, #0c09aa, transparent);
}

.section-prod {
  background: linear-gradient(45deg, #f58b1d, transparent) !important;
}

.blog-section {
  width: 100vw;
}

.section-prod,
.blog-section {
  overflow: hidden;
}

.section-gray {
  width: 100vw;
}
.section-lightblue {
  background: lightblue;
}

block-anim {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: lightgoldenrodyellow;
}

.horizontal-sections {
  position: relative;
  overflow-x: hidden;
  margin-top: 145px;
}
.pin-wrap,
.animation-wrap {
  display: flex;
  position: relative;
  z-index: 1;
  height: 100vh;
}

/* =================== Responsive =================== */
@media (max-width: 1024px) {
  .app--home-title {
    font-size: 7rem;
  }
  .work-title {
    top: -150px;
  }
}

@media (max-width: 991px) {
  .head-poster-image {
    height: 486px;
  }
  .head-content {
    bottom: 94px;
  }
  .app--home-title {
    font-size: 6rem;
  }
  .app--home-text {
    top: 135px;
  }
  .cards:nth-of-type(2),
  .cards:nth-of-type(3),
  .cards:nth-of-type(4) {
    margin-top: 0;
  }
  .work-title {
    font-size: 29px;
    top: -211px;
  }
}

@media (max-width: 767px) {
  .head-poster-image {
    height: 290px;
  }
  .head-content {
    bottom: 236px;
  }
  .app--home-title {
    font-size: 4rem;
  }
  .app--home-text {
    top: 81px;
  }
  .work-box {
    margin: 10px 20px;
  }
  .work-title {
    font-size: 2.5rem;
    top: -99px;
  }
}

@media (max-width: 1024px) {
  .mrv-services__title,
  .mrv-services__text,
  .alt-card-img,
  .alt-card-title,
  .alt-card-text,
  .mrv-feat__title,
  .mrv-feat__text,
  .mrv-works__title,
  .mrv-works__text,
  .work-title,
  .work-box,
  .blog-card-1 .blog-img,
  .blog-card-2 .blog-img,
  .blog-card-3 .blog-img,
  .blog-card-title-1,
  .blog-card-text-1,
  .blog-card-title-2,
  .blog-card-text-2,
  .blog-card-title-3,
  .blog-text,
  .blog-card-1,
  .blog-card-2,
  .blog-card-3,
  .head-poster-image,
  .mrv-button {
    clip-path: none !important;
  }
}

@media (max-width: 600px) {
  .head-content {
    display: block;
    position: static;
    margin-top: 36px;
  }
  .app--home-text {
    position: static;
  }
  .content-wrapper {
    margin-top: 46px;
  }
  .mrv-features,
  .mrv-works {
    margin-top: 60px;
  }
  .app--home-text {
    width: 100%;
  }
  .work-box {
    padding: 70px 31px;
  }
  .work-title {
    position: static;
  }
  .pin-wrap,
  .animation-wrap {
    display: block;
    height: auto;
  }
  .section-blog,
  .section-prod {
    display: block;
    min-height: auto;
  }
  .blog-head {
    margin-bottom: 0;
  }
  .blog-section,
  .section-gray,
  .blog-card-title,
  .blog-card-text {
    width: auto;
  }
  .mrv__blog,
  .mrv__product {
    padding: 100px 0;
  }
  .mrv-works {
    margin: 60px 0 0 0;
  }
  .head-poster {
    height: inherit;
  }
}

@media (max-width: 565px) {
  .blog-card {
    padding: 0 20px;
  }
  .alt-card-title {
    font-size: 20px;
  }
  .alt-card {
    padding: 10px 0 10px 0;
  }
  .work-box {
    height: 300px;
    background-size: cover;
    padding: 30px;
    margin-bottom: 30px;
  }
  .work-title {
    font-size: 30px;
  }
}

@media (max-width: 414px) {
  .horizontal-sections {
    margin-top: 60px;
  }
  .mrv__blog,
  .mrv__product {
    padding: 50px 0;
  }
  .blog-text {
    padding: 20px;
  }
}

/* =================== Footer =================== */
.footer {
  position: relative;
  padding-top: 48px;
}

.footer__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  .footer__group {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.footer__logo {
  height: 46px;
  width: 148px;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .footer__menu {
    margin-bottom: 30px;
  }
}

.footer__label {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: 575px) {
  .footer__label {
    font-size: 18px;
  }
}

.footer__item {
  margin-bottom: 8px;
}

.footer__link,
.footer__item {
  color: var(--white-color);
  transition: color 0.4s ease-in-out;
  font-size: 18px;
  font-weight: 500;
}

.footer__link:hover,
.footer__item:hover {
  color: var(--alt-color);
}

@media (max-width: 575px) {
  .footer__link,
  .footer__item {
    font-size: 16px;
  }
}

.footer__bottom {
  margin-top: 64px;
  padding: 40px 0;
  border-top: 1px solid var(--black-color);
}

@media (max-width: 767px) {
  .footer__bottom {
    margin-top: 24px;
  }
}

.footer__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .footer__box {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.footer__copyright {
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
}

@media (max-width: 767px) {
  .footer__copyright {
    margin-bottom: 24px;
  }
}

.social-item {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 6px;
  text-align: center;
  line-height: 33px;
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;
  font-size: 22px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.social-item:hover {
  color: var(--white-color);
  background-color: var(--alt-color) !important;
}
